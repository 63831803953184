.homelayout {
  background-image: url("../assets/images/home-background.png");
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}

.underline {
  text-decoration: underline !important;
}
